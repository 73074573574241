body {
    min-height: calc(100vh - 120px) !important;
    background: #f7f0e8 !important;
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;

    &.eboard-homepage {
        min-height: calc(100vh - 60px) !important;
        padding-bottom: 0;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: url('./assets/img/varyada-trim-bg.png') top center no-repeat;
        background-size: cover;
        transform: scaleX(-1);
    }
}

img {
    max-width: 100%;
    height: auto;
}


.post-news-header {
    padding: 80px 15px 40px 15px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    max-height: 500px;

    &__background {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: url('./assets/img/varyada-trim-bg.png') top center no-repeat;
        background-size: cover;
        clip-path: polygon(0 0, 100% 0, 100% 25%, 0 85%);
        z-index: 1;
    }

    img {
        z-index: 2;
        position: relative;
        max-height: 380px;
        width: auto;
    }
}

.post-offer-header {
    max-width: 960px;
    margin: 0 auto 50px auto;
}

.post-blog-header{
    h1 {
        padding: 30px 20px !important;
        font-size: 56px !important;
        font-weight: 700 !important;
        line-height: 68px !important;
        margin: 0 !important;
    }

    &__thumbnail {
        height: 27vw;
        overflow: hidden;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.eboard-post-content {
    max-width: 960px;
    margin: 0 auto;
}

.elementor-widget-sc-eboard-weather {
    .eboard-weather {
        color: #543a17;

        &__title {
            text-align: center;
        }

        &__subtitle {
            text-align: center;
            font-size: 16px;
        }

        &__today {
            display: flex;
            align-items: center;
            margin: 10px 0;

            &-symbol {
                width: 50%;
                text-align: center;

                img {
                    height: 70px;
                }
            }

            &-temp-condition {
                width: 50%;

                .temp,
                .condition {
                    width: 100%;
                    display: block;
                    text-align: center;
                    line-height: 1;
                }

                .temp {
                    font-size: 28px;
                }

                .condition {
                    font-size: 16px;
                }
            }
        }

        &__day {
            display: flex;
            align-items: center;
            margin: 15px 0;

            &-name {
                width: 30%;
                font-size: 14px;
            }

            &-symbol {
                width: 20%;
                text-align: right;

                img {
                    height: 25px;
                }
            }

            &-max-temp,
            &-min-temp {
                width: 25%;
                text-align: center;
                font-size: 14px;
            }

            &-max-temp {
                color: #994948;
            }

            &-min-temp {
                color: #2e8596;
            }
        }
    }
}

.eboard-icon-box {
    &.elementor-position-top {
        .elementor-image-box-img {
            width: 100% !important;

            img {
                height: 110px;
                width: auto;
                margin-bottom: 30px;
            }
        }
    }

    &--small {
        &.elementor-position-top {
            .elementor-image-box-img {
                img {
                    height: 80px;
                    margin-bottom: 0;
                }

                .elementor-image-box-title {
                    margin-top: 15px;
                }
            }
        }
    }

    &--big {
        &.elementor-position-top {
            .elementor-image-box-img {
                img {
                    height: 160px;
                    margin-bottom: 0;
                }

                .elementor-image-box-title {
                    margin-top: 15px;
                }
            }
        }
    }
}

.unauthorized {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &__content {
        font-size: 30px;
        padding: 0 100px;

        .code {
            font-size: 40px;
            font-weight: bold;
        }

        button {
            border-radius: 0;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.333;
            letter-spacing: 1.2px;
            padding: 20px 28px;
            text-align: left;
            text-transform: uppercase;
            text-decoration: none;
            background: linear-gradient(to right, #ad3458 0%, #ad3458 50%, #da4170 50%, #da4170 100%);
            color: #fff;
            background-size: 200% auto;
            background-position: 100% center;
            border: none;
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
                //background: transparent;
                background-position: 0 center;
            }
        }
    }
}

.sc-mall-map-widget {
    flex-wrap: wrap;
}

.sc-mall-map-widget .sc-mall-map__filters-wrapper {
    width: 100% !important;

    .sc-mall-map__fulltext {
        display: none !important;
    }
}

.sc-mall-map-widget .sc-mall-map-wrapper .sc-mall-map__controls {
    background: transparent;
}

.store-info {
    max-width: 960px;
    margin: 60px auto 0 auto;
    display: flex;

    &__open-hours {
        width: 40%;
        border-bottom: none;
        border-right: 1px solid #e6e6e6;
    }

    &__contacts {
        width: 60%;
    }
}

.elementor-widget-image a img[src$=".svg"] {
    width: auto !important;
}

.big-box {
    .elementor-widget-image-box {
        &.elementor-position-left {
            .elementor-image-box-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .elementor-image-box-content {
                width: auto;
            }
        }
    }
}

.mapplic-element {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;

    .mapplic-sidebar {
        width: 100% !important;
        float: none !important;
        order: 1;
        height: 445px !important;
        background-color: #f7f0e8;
        z-index: 1;

        .mapplic-filter {
            background-color: #f7f0e8;
        }

        .mapplic-list-container {
            border: 1px solid rgba(#000, 0.1);

            &::before {
                display: none;
            }
        }

        .mapplic-list {
            background: none !important;
            display: flex;
            flex-wrap: wrap;

            > li {
                width: 50%;
            }

            ol {
                background: none !important;
            }

            a {
                background: none !important;
            }
        }

        .mapplic-list-expandable {
            background-color: #f7f0e8;
            width: 100% !important;

            ol {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 50%;
                }
            }
        }
    }
    .mapplic-container {
        width: 100% !important;
        max-height: 700px !important;
        float: none !important;
        order: 2;
        padding-left: 0 !important;
        z-index: 2;
    }

    // Varyada - CLV map
    &#mapplic-id28149 {
        .mapplic-container {
            max-height: unset !important;
            height: 900px !important;
        }
    }
}

.mhd-btn{
    .eael-lightbox-btn {
        > .eael-modal-popup-button {
            border-radius: 0;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.333;
            letter-spacing: 1.2px;
            padding: 20px 28px;
            text-align: left;
            text-transform: uppercase;
            text-decoration: none;
            background-color: #ad3458 !important;
            background: linear-gradient(to right, #ad3458 0%, #ad3458 50%, #da4170 50%, #da4170 100%);
            color: #fff;
            background-size: 200% auto;
            background-position: 100% center;
            border: none;
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
                //background: transparent;
                background-position: 0 center;
            }
        }
    }
}

// popup jizdni rady
.eael-lightbox-popup-window {
    .eael-lightbox-container {
        height: 80vh;
        overflow: auto;
    }

    .mfp-close {
        width: 37px !important;
        height: 44px !important;
        right: 13px !important;
        background: #fff !important;
        color: #000 !important;

        &:focus {
            outline: none;
        }
    }
}

.mapplic-element {
    .mapplic-container {
        .mapplic-icon-reset {
            background-image: url("./assets/img/mapplic-icon-reset.svg");
        }

        .mapplic-icon-plus {
            background-image: url("./assets/img/mapplic-icon-plus.svg");
        }

        .mapplic-icon-minus {
            background-image: url("./assets/img/mapplic-icon-minus.svg");
        }

        .mapplic-icon-cross {
            background-image: url("./assets/img/mapplic-icon-cross.svg");
        }

        .mapplic-levels-up {
            background-image: url("./assets/img/mapplic-icon-arrow-up.svg");
        }

        .mapplic-levels-down {
            background-image: url("./assets/img/mapplic-icon-arrow-down.svg");
        }
    }
}

body:not(.single-eboard-post) {
    .elementor-widget-text-editor {
        a {
            color: inherit;
            pointer-events: none;
            text-decoration: none;
        }
    }
}

.store-info__contacts ul li a {
    color: #000 !important;
    pointer-events: none;
}

.single-sc_offer {
    .elementor-button-link {
        display: none;
    }
}

.eboard-nav-buttons {
    position: fixed;
    left: 0;
    top: 40vh;
    transform: translateY(-50%);
    z-index: 999;
    width: 50px;

    button,
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;
        background: #da4170;
        border: none;
        padding: 0;
        color: #fff;
        margin: 5px 0;

        svg {
            fill: #fff;
            display: block;
            width: 25px;
            height: 25px;
        }
    }
}

.error {
    position: fixed;
    top: 50vh;
    left: 20vw;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: bold;
    color: #da4170;
    text-align: center;
    width: 60vw;

    button.reload-btn {
        border-radius: 0;
        font-size: 20px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1.2px;
        padding: 20px 28px;
        text-align: left;
        text-transform: uppercase;
        text-decoration: none;
        background: linear-gradient(to right, #ad3458 0%, #ad3458 50%, #da4170 50%, #da4170 100%);
        color: #fff;
        background-size: 200% auto;
        background-position: 100% center;
        border: none;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
            //background: transparent;
            background-position: 0 center;
        }
    }
}

.is-loading {
    position: fixed;
    top: calc(50vh - 50px);
    left: calc(50vw - 50px);
    width: 100px;
    height: 100px;
    margin: 110px auto 0;
    border: solid 10px #da4170;
    border-radius: 50%;
    border-right-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name:             rotate;
    -webkit-animation-duration:         1.0s;
    -webkit-animation-iteration-count:  infinite;
    -webkit-animation-timing-function: linear;

    transition: all 0.5s ease-in;
    animation-name:             rotate;
    animation-duration:         1.0s;
    animation-iteration-count:  infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
